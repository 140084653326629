import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AppGrid from "../components/appGrid"

export default ({ pageContext: { data, logoPublicURLs }}) => (
  <Layout>
    <SEO title="Home" />
    <div className="wrapper">
      <div className="uk-text-center cs-site-description uk-margin">
        <h1 className="uk-heading-primary">Keyboard Shortcuts Directory</h1>
        <p><strong>Random Shortcuts is a directory of keyboard shortcuts for Mac programs.</strong></p>
        <p>Want to add a program? <a href="https://github.com/randomshortcuts/keyboard-shortcut-directory" target="_blank" rel="noopener noreferrer" >Contribute here</a>.</p>
      </div>
      <AppGrid appData={ data.allAppIndexJson.nodes } logoPublicURLs={ logoPublicURLs } />
    </div>
  </Layout>
)
