import React from "react"
import addAppImage from "../images/add-program.png"

const AppGrid = ({ appData, logoPublicURLs }) => {

  const Logo = ({ linkHref, imagePath, name }) => {
    if (!imagePath) {
      return;
    }
    return (
      <div className="uk-width-1-3 uk-width-1-6@s uk-text-center cs-program">
        <a href={ linkHref }>
          <img src={ imagePath } alt={ name } width="100" height="100" />
          <div className="cs-program-name">{ name }</div>
        </a>
      </div>
    )
  }

  const appLogos = appData.map(({ name, keyname, id }) => {
    const linkHref = `/${keyname}/`
    return <Logo linkHref={ linkHref } imagePath={ logoPublicURLs[keyname] } name={ name } key={ id } />
  })

  return (
    <div className="uk-container uk-container-small uk-margin-top">
      <div className="uk-grid">
        { appLogos }
        <Logo
          linkHref="https://github.com/randomshortcuts/keyboard-shortcut-directory"
          imagePath={addAppImage}
          name="Add a program"
        />
      </div>
    </div>
  )
}

export default AppGrid
